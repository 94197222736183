import utility from '@/common/utility'
import auth from '@/common/auth'

export default class G21Model {
  constructor (props) {
    if (props) {
      this.resourceId = props.g21Guid
      this.g21Guid = props.g21Guid
      this.planStatExaminationGuid = props.planStatExaminationGuid
      this.engineeringListGuid = props.engineeringListGuid
      this.firstYearNum = props.firstYearNum
      this.firstYearPercent = props.firstYearPercent
      this.secondYearNum = props.secondYearNum
      this.secondYearPercent = props.secondYearPercent
      this.thirdYearNum = props.thirdYearNum
      this.thirdYearPercent = props.thirdYearPercent
      this.sortId = props.sortId
      this.createUserGuid = props.createUserGuid
      this.createTime = props.createTime
      this.tenderGuid = props.tenderGuid
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.g21Guid) this.g21Guid = ''
    if (!this.planStatExaminationGuid) this.planStatExaminationGuid = ''
    if (!this.engineeringListGuid) this.engineeringListGuid = ''
    if (!this.firstYearNum) this.firstYearNum = ''
    if (!this.firstYearPercent) this.firstYearPercent = ''
    if (!this.secondYearNum) this.secondYearNum = ''
    if (!this.secondYearPercent) this.secondYearPercent = ''
    if (!this.thirdYearNum) this.thirdYearNum = ''
    if (!this.thirdYearPercent) this.thirdYearPercent = ''
    if (!this.sortId) this.sortId = 0
    if (!this.createUserGuid) this.createUserGuid = auth.getUserInfo().userGuid
    if (!this.createTime) this.createTime = utility.getCurrentTime()
    if (!this.tenderGuid) this.tenderGuid = ''
  }

  generatePrimaryKey () {
    this.g21Guid = utility.getUuid()
    this.resourceId = this.g21Guid
  }
}
