import RestService from '@/services/RestService'
import axios from 'axios'
import utility from '@/common/utility'
import auth from '@/common/auth'

let resourceName = 'g21'

let batchAddUrl = 'batchAdd'
let defaultDataUrl = 'defaultData'

class G21Service extends RestService {
  batchAdd (g21Models) {
    g21Models.forEach(g21Model => {
      g21Model.generatePrimaryKey()
    })
    return axios.post(utility.getRestFullUrl(this.resourceName, batchAddUrl), g21Models, {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getDefaultData (tenderGuid, planStatExaminationGuid) {
    return axios.get(utility.getRestFullUrl(this.resourceName, defaultDataUrl), {
      params: {
        tenderGuid,
        planStatExaminationGuid
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }
}

const g21Service = new G21Service(resourceName)

export default g21Service
